/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

//MATERIAL
import {
  withStyles,
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// CSS

import cssStyles from "./getnetPaymentSettings.module.css";

//IMPORTS
import {
  getAllPayments as getPayments,
  updatePayment,
} from "../../../reducers/paymentSlice";
import paymentsConstants from "../../../constants/payments.constants";
import { Notification } from "../../../containers/notification";
import { selectUser } from "../../../reducers/userSlice";
import mongoose from "mongoose";
import { loadingOff, loadingOn } from "../../../reducers/uiSlice";
import { selectCompany, getCompany } from "../../../reducers/companySlice";
import { createChangeRecord } from "../../../reducers/depositChangesSlice";
import companyService from "../../../utils/services/company";
import {
  selectChangesDeposit,
  setChanges,
} from "../../../reducers/depositSlice";
import dateUtils from "../../../utils/date";
import jwt from "jsonwebtoken";
import config from "../../../utils/config";
import InputComponent from "../../inputs";
import { useNavigate } from "react-router-dom";
import { selectSubsidiaries } from "../../../reducers/subsidiarySlice";
import { Tab, Tabs } from "@material-ui/core";

const defaultData = (company) => ({
  name: paymentsConstants.TYPES.Getnet,
  factor: 1,
  duration: 60,
  startTime: "01:00",
  endTime: "23:59",
  details: [],
  useSandbox: false,
  _id: new mongoose.Types.ObjectId().toHexString(),
  IdCompany: company._id,
  subsidiaryId: "",
});

function GetnetSettingsComponent(props) {
  const company = useSelector(selectCompany);
  const [apiSecret, setApiSecret] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [updated, setUpdated] = useState(false);
  const [payment, setPayment] = useState(defaultData(company));
  const [useGetnet, setUseGetnet] = useState(false);
  const [useSandbox, setUseSandbox] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [payments, setPayments] = useState([]);
  const subsidiaries = useSelector(selectSubsidiaries);
  const { enqueueSnackbar } = useSnackbar();
  const changes = useSelector(selectChangesDeposit);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [subsidiaryId, setSubsidiaryId] = useState(subsidiaries[0]?._id ?? "");

  useEffect(() => {
    if (subsidiaries.length) setSubsidiaryId(subsidiaries[0]._id);
  }, [subsidiaries.length]);

  useEffect(() => {
    const { Payments, UsePayments } = company;
    setUseGetnet(UsePayments && UsePayments.useGetnet ? true : false);
    if (Payments) {
      const found = Payments.filter(
        (item) => item.name === paymentsConstants.TYPES.Getnet
      );

      setPayments(found);
      setUpdated(false);
    }
  }, [company.Payments, company.UsePayments]);

  useEffect(() => {
    function getData() {
      const { useSubsidiaryPayment } = company;
      const found = payments.find((item) =>
        !useSubsidiaryPayment
          ? item.name === paymentsConstants.TYPES.Getnet && !item.subsidiaryId
          : item.name === paymentsConstants.TYPES.Getnet &&
            item.subsidiaryId === subsidiaryId
      );
      if (found) setPayment(found);
      else setPayment(defaultData(company));

      setUpdated(false);
    }

    if (
      (subsidiaryId !== "" || !company.useSubsidiaryPayment) &&
      payments.length
    )
      getData();
  }, [subsidiaryId, company.useSubsidiaryPayment, payments.length]);

  useEffect(() => {
    if (payment.details.length > 0) {
      setUseSandbox(payment.useSandbox);
      const detail = payment.details[0];
      try {
        if (detail.apiSecret && detail.apiSecret !== "")
          setApiSecret(jwt.verify(detail.apiSecret, config.jwtSecret));
      } catch (error) {
        console.log(error);
        setApiSecret("");
      }

      try {
        if (detail.apiKey && detail.apiKey !== "")
          setApiKey(jwt.verify(detail.apiKey, config.jwtSecret));
      } catch (error) {
        setApiKey("");
        console.log(error);
      }
    } else {
      setApiKey("");
      setApiSecret("");
    }
  }, [payment]);

  const handleCancel = () => {
    const detail = payment.details[0];
    setApiSecret(detail.apiSecret);
    setApiKey(detail.apiKey);
    setUpdated(false);
  };

  const handleSave = () => {
    handleCompanySave();
    const updatedPayment = {
      ...payment,
      IdCompany: company._id,
      subsidiaryId,
      useSandbox,
      details: [
        {
          ...payment.details[0],
          apiSecret: jwt.sign(apiSecret, config.jwtSecret),
          apiKey: jwt.sign(apiKey, config.jwtSecret),
        },
      ],
    };
    updatePayment(updatedPayment, user.token)
      .then((res) => {
        setPayment(updatedPayment);
        setUpdated(false);
        getPayments(company._id, user.token, dispatch);
        enqueueSnackbar(
          Notification({
            text: "Métodos de pagos actualizados",
            variant: "success",
            withDetails: false,
          })
        );
      })
      .catch((err) =>
        enqueueSnackbar(
          Notification({
            text: "Error al actualizar métodos de pago",
            variant: "error",
            withDetails: false,
          })
        )
      );
  };

  const handleCompanySave = async () => {
    try {
      dispatch(loadingOn());
      await companyService.updateCompany(
        company._id,
        {
          _id: company._id,
          UsePayments: {
            ...company.UsePayments,
            useGetnet,
          },
        },
        user.token
      );

      await getCompany(company._id, dispatch);
      enqueueSnackbar(
        Notification({
          text: "Datos de la tienda actualizados",
          variant: "success",
          withDetails: false,
        })
      );
      setUpdated(false);
      createChangeRecord(
        {
          user: user,
          username: user.username,
          fullName: `${user.firstName} ${user.lastName}`,
          idUser: user.id,
          idCompany: company._id,
          idObject: company._id,
          module: "settings",
          submodule: "general",
          createdDate: dateUtils.getUTC().slice(0, 19).replace("T", " "),
          changes: changes,
        },
        user.token,
        dispatch
      ).catch((err) => console.log(err));
    } catch (err) {
      enqueueSnackbar(
        Notification({
          text: "Error al actualizar datos de la tienda",
          variant: "error",
          withDetails: false,
        })
      );
    } finally {
      dispatch(loadingOff());
    }
  };

  const updateChanges = (fieldName, newValue) => {
    const newChanges = [];
    newChanges.push(...changes);
    var index = -1;
    const change = {
      text: "",
      oldValue: null,
      newValue: null,
      date: dateUtils.getUTC().slice(0, 19).replace("T", " "),
    };

    change.newValue = newValue;
    change.oldValue = company && company[fieldName] ? company[fieldName] : "";

    switch (fieldName) {
      case "useGetnet":
        change.text = "Usar método de pago Getnet";
        change.oldValue = company.UsePayments[fieldName];
        break;
      default:
        break;
    }

    index = newChanges.findIndex(
      (item) => item.text.toString() === change.text.toString()
    );

    if (index !== -1) {
      change.oldValue = newChanges[index].oldValue;
      newChanges[index] = change;
    } else {
      newChanges.push(change);
    }
    dispatch(setChanges(newChanges));
  };

  const handleUsePayment = (event, name) => {
    switch (name) {
      case "useGetnet":
        setUseGetnet(event.target.checked);
        updateChanges("useGetnet", event.target.checked);
        break;
      default:
        break;
    }
    setUpdated(true);
  };
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={cssStyles.parentContainer}>
        <button className={cssStyles.backButton} onClick={() => handleClose()}>
          <span>
            <ArrowBackIcon />
          </span>
          Regresar
        </button>
        <div className={cssStyles.infoContainer}>
          <div className={cssStyles.titleLogoContainer}>
            <div className={cssStyles.containerLogo}>
              <img
                src="https://banco.santander.cl/uploads/000/015/050/0affa7e1-10e5-45ff-b0e3-7616aee7d686/original/getnet_logo.svg"
                alt="getnet"
                width={200}
              />
            </div>
            <div className={cssStyles.serviceStatusContainer}>
              {!useGetnet ? (
                <div className={cssStyles.containerLogo}>
                  <img
                    className="stateOffMargin"
                    src="https://s3.amazonaws.com/lumarketo.cl/clock-steps-home.svg"
                    alt=""
                  />
                  Inactivo{" "}
                </div>
              ) : (
                <div className={cssStyles.containerLogo}>
                  {" "}
                  <img
                    src="https://s3.amazonaws.com/lumarketo.cl/check-steps-home.svg"
                    alt=""
                  />{" "}
                  Activo{" "}
                </div>
              )}
            </div>
          </div>
          <div className={cssStyles.infoItemContainer}>
            <p className={cssStyles.infoItemTitle}>
              Permite a tus clientes pagar con
            </p>

            <div className={cssStyles.outerContainerLogo}>
              <div className={cssStyles.containerLogo}>
                <img
                  className="mercadoPagoheaderTextLogoSize"
                  src="https://s3.amazonaws.com/lumarketo.cl/tarjeta-de-credito-mercadopago.svg"
                  alt=""
                />
                <p className={cssStyles.infoItemTitlePayment}>
                  {" "}
                  Tarjeta de Débito/Crédito
                </p>
              </div>
              <div className={cssStyles.containerLogo}>
                <img
                  className="mercadoPagoheaderTextLogoSize"
                  src="https://s3.amazonaws.com/lumarketo.cl/mensual-removebg-preview-calendario-mercadopago.svg"
                  alt=""
                />
                <p className={cssStyles.infoItemTitlePayment}>
                  {" "}
                  Cuotas sin interés
                </p>
              </div>
            </div>
          </div>
          <div className={cssStyles.infoItemContainer}>
            <p className={cssStyles.infoItemTitle}>
              Getnet de Santander te ofrece una única manera de como obtener el
              dinero acumulado
            </p>
            <div className={cssStyles.descriptionContainer}>
              <div className={cssStyles.descriptionInnerContainer}>
                <p className={cssStyles.infoItem}>Pagas</p>
                <p className={cssStyles.infoItem}>
                  <span className="porcentageNumber"> 1,45% </span> (rubro
                  gastronómico)
                </p>
              </div>
              <div className={cssStyles.descriptionInnerContainer}>
                <p className={cssStyles.infoItem}>Retiras tu dinero en</p>
                <p className={cssStyles.infoItem}>
                  <span className="porcentageNumber"> De inmediato </span>
                </p>
              </div>
            </div>
          </div>
          <div className={cssStyles.descriptionContainer}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.getnet.cl/hazte-cliente"
            >
              {" "}
              <span className="mercadopagoFotterButtonBlank">
                Ver: ¿Cómo configurar Getnet?
              </span>
            </a>
            <p className={cssStyles.infoItem}>
              Pago seguro{" "}
              <img
                src="https://s3.amazonaws.com/lumarketo.cl/tarjeta-fondo-background.svg"
                alt=""
              />
            </p>
          </div>
          <button
            className={cssStyles.buttonConfig}
            onClick={() => {
              setShowInputs((p) => !p);
            }}
          >
            Configurar
            <span>{!showInputs ? <ExpandMoreIcon /> : <ExpandLessIcon />}</span>
          </button>
          {showInputs && company.useSubsidiaryPayment && (
            <Tabs
              value={subsidiaryId}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setSubsidiaryId(newValue)}
              aria-label="disabled tabs example"
            >
              {subsidiaries.map((item) => (
                <Tab label={item.Name} value={item._id} />
              ))}
            </Tabs>
          )}
          {showInputs && (
            <div className={cssStyles.configSettingsContainer}>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Identificador del sitio
                </label>

                <InputComponent
                  placeholder="12345678"
                  type="text"
                  validation={apiKey === ""}
                  helperText={
                    apiKey === ""
                      ? "Recuerde indicar el identificador del sitio"
                      : ""
                  }
                  value={apiKey}
                  handler={(e) => {
                    setUpdated(true);
                    setApiKey(e.target.value);
                  }}
                />
              </div>
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>
                  Llave transaccional
                </label>

                <InputComponent
                  placeholder="12345678"
                  type="text"
                  validation={apiSecret === ""}
                  helperText={
                    apiSecret === ""
                      ? "Recuerde indicar la llave transaccional"
                      : ""
                  }
                  value={apiSecret}
                  handler={(e) => {
                    setApiSecret(e.target.value);
                    setUpdated(true);
                  }}
                />
              </div>

              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Activar Sandbox</label>
                <Checkbox
                  style={{
                    color: "#122526",
                    margin: "0px 5px",
                    padding: 0,
                  }}
                  checked={useSandbox}
                  onChange={(e) => {
                    setUpdated(true);
                    setUseSandbox(e.target.checked);
                  }}
                  value={useSandbox.toString()}
                  color="primary"
                  id={"useSandbox"}
                />
              </div>
              <br />
              <div
                style={{
                  margin: "5px 0px",
                  borderBottom: "1px solid gray",
                }}
              />
              <br />
              <div className={cssStyles.inputContainer}>
                <label className={cssStyles.labelInput}>Activar</label>
                <Checkbox
                  style={{
                    color: "#122526",
                    margin: "0px 5px",
                    padding: 0,
                  }}
                  checked={useGetnet}
                  onChange={(e) => handleUsePayment(e, "useGetnet")}
                  value={useGetnet.toString()}
                  color="primary"
                  id={"useGetnet"}
                />
              </div>
            </div>
          )}
          {showInputs && (
            <div className={cssStyles.buttonsContainer}>
              <button
                className={cssStyles.cancelButton}
                disabled={!updated}
                onClick={() => handleCancel()}
              >
                Cancelar
              </button>
              <button
                className={cssStyles.confirmButton}
                disabled={!updated}
                onClick={() => handleSave()}
              >
                Guardar
              </button>
            </div>
          )}
        </div>
      </div>
    </MuiThemeProvider>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: "5%",
    [theme.breakpoints.up("xs")]: {
      marginTop: "15%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "1%",
    },
  },
  centerTitle: {
    textAlign: "center",
  },
  componentDescription: {
    padding: "0 5px",
  },
  componentContainerFab: {
    display: "flex",
    position: "absolute",
    top: "90%",
  },
  componentHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  componentSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  textField: {
    width: "100%",
    margin: "5px 0 5px 0px",
  },
  bankTextField: {
    width: "100%",
  },
  unknowBankTextField: {
    width: "86%",
  },
  componentAddItem: {
    display: "flex",
    justifyContent: "center",
  },
  bankCard: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    height: 360,
    width: 450,
    marginBottom: 10,
  },
  cover: {
    width: 200,
    backgroundRepeat: "round",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: 250,
  },
  content: {
    flex: "1 0 auto",
  },
  deleteIcon: {
    position: "absolute",
    left: "88%",
    top: "-2%",
  },
  subtitleContainer: {
    margin: "auto 0px",
  },
});

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "Roboto, sans-serif !important",
  },
  palette: {
    primary: { main: "#122526" },
  },
  overrides: {
    MuiFormGroup: {
      root: {
        flexDirection: "column !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: "-5%",
        marginLeft: "5%",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#122526 !important",
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before": {
          borderBottom: "2px solid #122526",
        },
        "&:after": {
          borderBottom: "2px solid #122526",
        },
        "&:before": {
          // borderBottom: '2px solid #122526'
        },
      },
    },
    MuiFormControl: {
      root: {
        width: 100,
        margin: "0px 5px",
      },
    },
    MuiCardHeader: {
      root: {
        background: "#122526",
      },
      title: {
        color: "white",
      },
    },
  },
});

export default withStyles(styles)(GetnetSettingsComponent);
